/* BASICS FOR ASSAN ------------------------------- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

@media (max-width: 767px) {
	.text-xs-center {
		text-align: center;
	}
}

/* SIPSTACK ------------------------------- */

[data-bs-theme='light'] {
	--ss_bg: white;
	--ss_input-color: white;
	--ss-text-color: black;
	--ss-border-color: lightgrey;
	--bw: white;
	--nav-link-active: black;
	--bg-light: #f5f5f5;
}

[data-bs-theme='dark'] {
	--ss_bg: #202020;
	--ss_input-color: #202020;
	--ss-text-color: white;
	--ss-border-color: rgb(92, 91, 91);
	--bw: #0d0f0c;
	--nav-link-active: #35bb8a;
	--bg-light: #202020;
}

.ss-footer-sub-link + .ss-footer-sub-link::before {
	content: ' | ';
	margin-right: 1rem;
	margin-left: 0.5rem;
}

.ss-small-sm {
	font-size: 0.8em;
}
.ss-small-xs {
	font-size: x-small;
}

.ss-text-muted {
	/* --bs-text-opacity: 0; */
	color: #606060 !important;
}

.ss-trunc-wrapper {
	padding: 20px;
	background: #eaeaea;
	max-width: 400px;
	margin: 50px auto;
}

/* .ss-trunc {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
} */
.ss-trunc-sm {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 90%;
}

/* capitalize api responses */
.ss-cap-first {
	text-transform: capitalize;
}
.ss-cap-all {
	text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
	.ss-hide-large {
		display: none;
		visibility: hidden;
	}
}

@media screen and (max-width: 600px) {
	.ss-hide-mobile {
		display: none;
		visibility: hidden;
	}
}

@media screen and (min-width: 768px) {
	.mobile-only {
		display: none;
	}
}

.ss-href {
	cursor: pointer;
}

/* The below code is for dark mode */
.card,
.dropdown-menu,
.dropdown-item:hover,
.modal-content {
	background-color: var(--ss_bg) !important;
	border-color: var(--ss_bg);
}

.list-group-item,
.dropdown-item {
	background-color: var(--ss_bg) !important;
	border-color: var(--ss_bg);
	color: var(--ss-text-color);
}

.dropdown-toggle {
	color: var(--ss-text-color);
}

.ss_bg {
	background-color: var(--bw);
}

.ss-bg-light {
	background-color: var(--bg-light) !important;
}
.form-control,
.form-check-input,
.form-control:focus {
	background-color: var(--ss_input-color);
	border-color: var(--ss-border-color);
	color: var(--ss-text-color);
}

.active.nav-link {
	color: var(--nav-link-active) !important;
}

.ss-search-bar {
	max-width: 30%;
	margin-left: 25%;
}

.dot {
	height: 0.6em;
	width: 0.6em;
	background-color: rgb(9, 173, 248);
	border-radius: 50%;
}

.middle {
	display: inherit;
	vertical-align: middle;
}

.payment-card-logo {
	max-width: 5em;
	height: auto;
}

.record-button {
	font-size: 8em;
	padding-left: auto;
	padding-right: auto;
	color: grey;
}

.nav-bar-logo {
	max-width: 250%;
	height: auto;
}

.dropdown-item.active,
.dropdown-item:active {
	color: var(--bs-primary-text);
	text-decoration: none;
	background-color: var(--bs-dropdown-link-active-bg);
}

.ss-link-result {
	cursor: pointer;
}
